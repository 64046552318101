/* You can add global styles to this file, and also import other style files */

@import '~perfect-scrollbar/css/perfect-scrollbar.css';

.ps .ps__scrollbar-y-rail {
  left: 3px;
  right: auto;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  color: #94a3ab !important;
  background-color: #000 !important;
  font-family: Lora, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
}

h1 {}

a:hover {
  color: #e0ca7c !important;
}

a {
  color: #94a3ab !important;
}

.wrapper {
  height: 100%;
}

.row {
  display: flex;
}

.column {
  flex: 16.66%;
  padding: 15px;
}



.card {
  background-color: #333332 !important;
  margin: 10px !important;
  transition: transform .2s;
  /* Animation */
}

.card:hover {
  transform: scale(1.2);
}

.disc-link-logo {
  height: 35px;
  display: block;
  margin: 2px;
  width: 35px;
  float: left;
  position: center;

}

.platform-logo {
  background-color: #333332 !important;
  margin: 4px;
  padding: 9px;
  transition: transform .2s;
  /* Animation */
}

.platform-logo:hover {
  transform: scale(1.2);
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.platform-links {
  position: center;
  top: 8px;

}


.ref-link {
  color: #687669;
}

.ref-link:hover {
  color: #e0ca7c !important;
}

.fa {
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  /* padding: .5rem 1rem; */
  font-size: 1rem;
  border-radius: .25rem;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

ul {
  list-style-type: none;
  margin-top: -10px;
  padding: 0;
}

.center-vertical {
  transform: translateY(30%);

}

.clearfix:after {
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

